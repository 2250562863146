<template>
  <v-container class="down-top-padding" fluid>

    <div v-if="!is_creation">
      <BaseBreadcrumb title="Prodotto PRESENTE IN COMMERCIO"></BaseBreadcrumb>
    </div>
    <div v-else>
      <BaseBreadcrumb title="Inserimento Nuovo Prodotto"></BaseBreadcrumb>
    </div>


    <v-row>
      <v-col cols="12">
        <v-btn
            class="mr-4"
            color="primary"
            x-large
            @click="goBack"
        >
          BACK
        </v-btn>

        <v-spacer></v-spacer>

        <p>
          <small>I campi segnati con * non possono essere modificati, preme Back se desideri cambiare MINSAN</small>
        </p>

      </v-col>

    </v-row>


    <v-row>

      <v-form v-model="valid">
        <v-container>
          <v-row>

            <v-col
                cols="12">
              <v-text-field
                  v-model="minsan"
                  label="MINSAN"
                  prefix="*"
                  readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="!is_creation" cols="12">
              <v-text-field
                  v-model="product_id"
                  label="Product Code (read only)"
                  prefix="*"
                  readonly
              ></v-text-field>
            </v-col>

            <v-col
                cols="12">
              <v-text-field
                  v-model="product_name"
                  label="NOME DEL PRODOTTO"
                  prefix="*"
                  readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                  v-model="amount"
                  label="GIACENZA"
                  outlined
                  required
              ></v-text-field>
            </v-col>



            <v-col cols="12">

              <p>
                <b>*Utilizzare punto come separatore decimali</b>
              </p>

              <v-col cols="6">
                <p>Prezzo</p>
                <vue-numeric
                    class="white"
                    v-model="price"
                    currency="€"
                    label="Prezzo"
                    output-type="String"
                    required
                    separator=","
                    v-bind:precision="2"
                ></vue-numeric>
              </v-col>

              <v-col cols="6">
                <p>Prezzo di listino</p>
                <vue-numeric
                    class="white"
                    v-model="list_price"
                    currency="€"
                    label="Prezzo di listino"
                    output-type="String"
                    required
                    separator=","
                    v-bind:precision="2"
                ></vue-numeric>
              </v-col>



            </v-col>

            <v-col v-if="!is_creation" cols="12">
              <v-switch
                  v-model="status"
                  :label="`Prodotto Attivo: ${status}`"
                  inset
              ></v-switch>
            </v-col>


            <v-col v-if="is_creation">

              <ul id="v-for-object">
                <li v-for="(value, name) in available_promotions" v-bind:key="value" >

                  <v-checkbox
                      :value="value"
                      :label="name"
                      color="indigo"
                      hide-details
                      @change="check"
                      v-model="subcategories"
                  ></v-checkbox>
                </li>
              </ul>

              <br>
              <v-divider></v-divider>
              <br>

              <v-checkbox
                  v-model="force_restricted"
                  label="Forza caricamento BANCO"
                  color="indigo"
                  hide-details
              ></v-checkbox>
            </v-col>

            <v-col v-if="this.main_image !== ''" cols="12">

              <v-img
                  :src="this.main_image"
                  class="white"
                  contain
                  height="125"
              ></v-img>
            </v-col>


          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn
                  class="mr-4"
                  color="primary"
                  x-large
                  @click="submit"
              >
                SALVA PRODOTTO
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Product_Detail",
  data: () => ({
    pharmacy_id: '',
    is_creation: false,
    source: '',
    valid: false,
    product: '',
    product_id: '',
    product_code: '',
    amount: 0,
    main_category: '',
    product_name: '',
    prezzo: '',
    category: '',
    p_description: '',
    price: '',
    list_price: '',
    detailed_image: '',
    main_image: '',
    status: true,
    force_restricted: false,
    current: {},
    pharmacy: {},
    minsan: '',
    page: {
      title: "Product_Detail"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Product_Detail",
        disabled: true
      }
    ],
    subcategories:[],
  }),
  mounted() {

    if (this.$route.params.data) {

      this.pharmacy_id = this.$route.params.id;
      this.current = this.$route.params.data;
      this.pharmacy = this.$route.params.pharmacy;
      this.minsan = this.$route.params.minsan;
      this.product_code = this.current.product_code;

      if (this.product_code === undefined) {
        this.creating_new_product();
        return;
      }

      this.product_id = this.current.product_id
      this.product_name = this.current.product


      if(this.current.main_pair.length && this.current.main_pair !== undefined) {
        this.main_image = this.current.main_pair.detailed.image_path
      }


      //this.product_id = this.current.product_id
      this.product = this.current.product

      this.amount = this.current.amount
      this.price = this.current.price
      this.list_price = this.current.list_price


      console.log('L this.price  ===', this.price );
      console.log('L this.listprice  ===', this.list_price );

      if (this.current.status === 'A') {
        this.status = true
      } else {
        this.status = false
      }
      // this.p_description = this.current.p_description

      //console.log("this.current 192", this.current);
    }
  },
  computed: {
    available_promotions: function () {

      if (this.pharmacy['available_promotions'] !== undefined) {
        let promotions = {}

        if (this.pharmacy['available_promotions'].modificati !== '') {
          promotions['modificati'] = this.pharmacy['available_promotions'].modificati
        }

        if (this.pharmacy['available_promotions'].offerte_in_farmacia !== '') {
          promotions['offerte_in_farmacia'] = this.pharmacy['available_promotions'].offerte_in_farmacia
        }

        if (this.pharmacy['available_promotions'].promo_online !== '') {
          promotions['promo_online'] = this.pharmacy['available_promotions'].promo_online
        }

        return promotions
      }
      return  {}

    }

  },
  methods: {

    check(event) {
      this.$nextTick(() => {
        console.log(this.subcategories, event)
      })
    },

    creating_new_product() {

      this.is_creation = true

      if (this.current['p_images'].length) {
        this.main_image = this.current['p_images'][0]['product_image'];
      }

      this.status = true


      if (this.current['te001'].length) {
        // Parafarmaco
        let _farmaco = this.current['te001'][0]
        console.log('Parafarmaco')
        console.log(_farmaco)
        this.product_name = _farmaco['FDI_0004']
        this.list_price = this.getListPrice(this.current);
        this.price = this.getListPrice(this.current);
        this.amount = 1


      } else if (this.current['te002'].length) {

        /// Farmaco
        let _farmaco = this.current['te002'][0];
        console.log('Farmaco')
        console.log(_farmaco)
        this.product_name = _farmaco['FDI_0004']
        this.list_price = this.getListPrice(this.current);
        this.price = this.getListPrice(this.current);
        this.amount = 1

      }



    },

    getListPrice(farmaco) {

      if (farmaco['te001'].length) {
        // PARAFARMACI
        // TE001.FDI_9007
        return farmaco['te001'][0]['FDI_9007']

      } else if (farmaco['te002'].length) {
        // FARMACO DA BANCO
        // TE002.FDI_9007
        return farmaco['te002'][0]['FDI_9007']
      } else {
        return 0
      }

    },

    create_product() {

      let data = {
        minsan: this.minsan,
        amount: this.amount,
        price: this.price,
        list_price: this.list_price,
        status: 'A',
        subcategories: this.subcategories,
        pharmacy: this.pharmacy
      }


      console.log('L  ===', data);

      let path = 'create/' + this.pharmacy_id + '/' + this.minsan;

      const config = {
        method: 'post',
        url: this.$BASEURL + path,
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios(config)
          .then(function (response) {
            console.log(response);
            alert('Saved successfully');
            this.goBack();
          }.bind(this))
          .catch(function (error) {
            alert(error.toLocaleString());
          });
    },

    submit() {


      if (this.is_creation) {
        this.create_product();
        return
      }

      let data = {
        product_id: this.product_id,
        amount: this.amount,
        price: this.price,
        list_price: this.list_price,
        status: this.get_current_status()
      }

      this.price = data.price;
      this.product = data.product;
      this.main_category = data.main_category;


      let path = 'update/' + this.pharmacy_id + '/' + this.product_id;

      const config = {
        method: 'POST',
        url: this.$BASEURL + path,
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };


      axios(config)
          .then(function (response) {
            console.log(response);
            alert('Saved successfully');
            this.goBack();
          }.bind(this))
          .catch(function (error) {
            //console.log("error 335", error);
            alert(error.toLocaleString());
          });
    },
    get_current_status() {
      if (this.status === true) {
        return 'A'
      } else {
        return 'D'
      }
    },

    goBack() {
      this.$router.back();
    },

    resetForm() {
      this.$router.back();
    }
  }

}
</script>

<style scoped>

</style>
